<template>
  <div style="padding: 48px 0 56px 0; width: 1200px; margin: 0 auto">
    <p class="tc" style="
        margin: 0 auto;
        margin-bottom: 24px;
        width: 200px;
        height: 64px;
        line-height: 64px;
        background: #007bc7;
        font-size: 32px;
        font-weight: 800;
        color: #fff;
        border-radius: 16px;
      ">
      查询结果
    </p>
    <div class="clearfix whiteBgBox" style="padding: 0 24px; height: 56px; line-height: 56px">
      <p class="fl" style="font-size: 18px; font-weight: 800; color: #333">
        {{ $route.query.key }}（<span>{{ totalCount }}</span>）
      </p>
      <div class="fr searchItem">
        <el-input @clear="getList(1)" suffix-icon="el-icon-search" clearable @blur="getList(1)" @keyup.enter.native="getList(1)" placeholder="请输入样品名称搜索" v-model="sampleName"></el-input>
      </div>
    </div>
    <div class="whiteBgBox" v-loading="$store.state.tableLoading" style="padding: 10px 24px; margin-top: 8px">
      <el-table border highlight-current-row style="width: 100%" :data="tableData">
        <el-table-column type="index" width="50" label="序号"></el-table-column>
        <el-table-column label="机构名称" prop="companyName"></el-table-column>
        <el-table-column label="报告编号" prop="reportNo"></el-table-column>
        <el-table-column label="样品名称" prop="sampleName"></el-table-column>
        <el-table-column label="规格型号" prop="specModel"></el-table-column>
        <el-table-column label="委托人(单位)" prop="wtOrgName"></el-table-column>
        <el-table-column label="检验检测类别" prop="inspectionTestingType"></el-table-column>
        <el-table-column label="报告发布日期" prop="reportReleaseDate"></el-table-column>
        <el-table-column label="结论" prop="conclusion"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="openDetail(scope.row)">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination-container">
        <div class="pagePosition tc" style="margin-top: 20px">
          <el-pagination background layout="prev, pager, next" prev-text="<" next-text=">" :total="totalCount" :page-size="25" :current-page.sync="pageNumber" @current-change="pageChange">
          </el-pagination>
        </div>
      </div>
    </div>
    <Dialog ref="visible"></Dialog>
  </div>
</template>

<script>
import Dialog from "./detail_report.vue";
export default {
  components: {
    Dialog
  },
  data() {
    return {
      tableData: [],
      totalCount: 0,
      pageNumber: 1,
      sampleName: ''
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(p) {
      let param = {
        companyName:this.$route.query.key,
        sampleName:this.sampleName,
        page: p ? p : this.pageNumber,
        limit: 25
      };
      this.$store.state.tableLoading = true;
      this.$post(
        this.$requestUrl + "qingzhi-admin/web/getByCompanyName",
        param
      ).then((res) => {
        this.$store.state.tableLoading = false;
        if (res.code === 500) {
          this.$message.error(res.msg);
          return;
        }
        this.tableData = res.reportList ? res.reportList.list : [];
        this.totalCount = res.reportList ? res.reportList.totalCount : 0;
      });
    },
    openDetail(row) {
      this.$fetch(this.$requestUrl + "qingzhi-admin/web/getReportDetail", {
        id: row.id,
      }).then((res) => {
        if (res.code === 500) {
          this.$message.error(res.msg);
          return;
        }
        this.$refs.visible.visible = true;
        this.$refs.visible.formData = res.report;
      });
    },
    pageChange(p) {
      this.pageNumber = p;
      this.getList(p);
    }
  }
};
</script>
